import { Link } from "gatsby"
import { graphql } from "gatsby"
import * as React from "react"
import Sidebar from '../components/sidebar';
import { Helmet } from "react-helmet"


// markup
const IndexPage = ({data}) => {
  return (
    <Sidebar>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Video Games Are Bad</title>
        </Helmet>
        <h2 className="pb-2 text-left text-2xl">Featured Posts</h2>
        <div className="flex flex-wrap pb-2">
          {
            data.featuredPost.edges.map(x => (
              <div className="p-1 cursor-pointer mx-auto">
                <Link to={x.node.path}>
                  <div className="relative">
                    <div className="overflow-hidden">
                      <img src={`https://${x.node.featuredImage.file.url.substring(2)}?w=266`} alt={`${x.node.title}`} className="transform scale-100 hover:scale-125 duration-300 ease-out ease-in"/>
                    </div>
                    <h4 className="absolute bg-opacity-75 text-white p-2 bg-black text-center bottom-0 w-full">{x.node.title}</h4>
                  </div>
                </Link>
              </div>
            ))
          }
        </div>
        <h2 className="pb-2 text-2xl">Featured Videos</h2>
        <div className="flex flex-wrap pb-4">
          {
            data.featuredVideo.edges.map(x => (
              <div className="p-1 cursor-pointer mx-auto">
                <Link to={x.node.path}>
                  <div className="relative">
                    <div className="overflow-hidden">
                      <img src={`https://${x.node.featuredImage.file.url.substring(2)}?w=538`} alt={`${x.node.title}`} className="transform scale-100 hover:scale-105 duration-300 ease-out ease-in"/>
                    </div>
                    <h4 className="absolute bg-opacity-75 text-white p-2 bg-black text-center bottom-0 w-full">{x.node.title}</h4>
                  </div>
                </Link>
              </div>
            ))
          }
        </div>
    </Sidebar>   
  )
}

export const pageQuery = graphql`
{
  featuredPost:allContentfulPost(
    sort: {order:DESC, fields: [createdDate]}
    filter:{featured: {eq:true}}
  ){
    edges{
      node{
        title
        path
        featuredImage{
					file {
            url
          }
        }
      }
    }
  }
   featuredVideo:allContentfulPost(
    sort: {order:DESC, fields: [createdDate]}
    filter:{featuredVideo: {eq:true}}
  ){
    edges{
      node{
        title
        path
        featuredImage{
					file {
            url
          }
        }
      }
    }
  }
} 
`

export default IndexPage
